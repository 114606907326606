<template>
  <div
    class="expired-subscription-container d-flex justify-center align-center blue"
  >
    <h1 class="white--text">Subscription has expired</h1>
    <div class="expired-subscription-link-container">
      <a
        :href="contactUsUrl"
        target="_blank"
        class="expired-subscription-container__link text-h6 white--text text-decoration-underline"
        >Contact us to renew</a
      >
      <router-link
        to="/"
        class="expired-subscription-container__link text-h6 white--text text-decoration-underline"
      >
        Back to site
      </router-link>
    </div>
  </div>
</template>
<script>
import { contactUsUrl } from "@/helpers/variables";

export default {
  name: "ExpiredSubscription",
  data() {
    return {
      contactUsUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
.expired-subscription-container {
  position: relative;
  height: 100%;
  flex-direction: column;

  h1 {
    font-size: 7vw;
    line-height: normal;
    margin-bottom: 0;
  }
  .expired-subscription-link-container {
    display: flex;
    flex-direction: column;
  }

  &__link {
    text-align: center;
    width: 100%;
  }
}
</style>
